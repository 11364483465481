<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("attendances") }}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button v-if="!saveLoading" class="btn btn-sm btn-success" @click="edit ? editAttendances() : addAttendances()" @changed="onEdit(item)">
          <i v-if="!edit" class="ti-pencil mL-5" style="color: #fff"> {{ $t('edit') }}</i>
          <i v-else class="ti-save mL-5" style="color: #fff"> {{ $t('save') }}</i>
        </button>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <div class="form-check col-sm-1" style="display: inline-block;">
          <label class="form-label form-check-label">
            <input class="form-check-input" type="radio" v-model="type" id="gridRadios1" value="1" checked="checked" style="margin-right: 40px;" @change="handleRadioChange">
             {{ $t('today') }}</label></div>
             <div class="form-check col-sm-1" style="display: inline-block;">
          <label class="form-label form-check-label">
            <input class="form-check-input" type="radio" v-model="type" id="gridRadios1" value="2" style="margin-right: 70px;" @change="handleRadioChange">
             {{ $t('specifiqueDate') }}</label></div>
         <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm" v-if="type ==2">
              <div class="form-group row pB-30 pT-30">
              
                <div class="col-sm-2">
                  <sui-dropdown
              selection
              :options="years()"
              v-model="currentYear"
              :placeholder="this.$t('year')"
            />
                </div>

                <div class="col-sm-2">
                  <sui-dropdown
              selection
              :options="months"
              v-model="currentMonth"
              :placeholder="this.$t('month')"
            />
                </div>

                <div class="col-sm-2">
                  <sui-dropdown
              selection
              :options="days()"
              v-model="currentDay"
              :placeholder="this.$t('day')"
            />
                </div>
                
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary mL-10"
                    style="height: 37px;"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 70%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr role="row">
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("branch") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    {{ $t("status") }}
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in attendances" :key="item.id">
                <tr role="row" class="odd">
                  <td>
                    {{item.name }}
                  </td>
                  <td>
                    {{ getTranslation(item.branch.ar_name , item.branch.en_name)}}
                  </td>
                  <td v-if="edit">
                    <sui-form-field style="vertical-align:middle;">
                      <sui-checkbox :label="$t('present')" v-model="item.selected" />
                    </sui-form-field>
                  </td>
                  <td v-else-if="item.attendances.length !=0" @click="changeStatus(item)">
                    <a href="javascript:"
                      ><span
                        class="
                          badge
                          bgc-green-50
                          c-green-700
                          p-10
                          lh-0
                          tt-c
                          badge-pill
                        "
                        >{{ $t("present") }}</span
                      ></a
                    >
                  </td>
                  <td v-else>
                    <a href="javascript:"
                      ><span
                        class="
                          badge badge-pill
                          bgc-red-50
                          c-red-700
                          p-10
                          lh-0
                          tt-c
                          badge-pill
                        "
                        >{{ $t("absent") }}</span
                      ></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!attendances.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        saveLoading: false,
        statusLoading: true,
        date: '',
        attendances: [],
        edit: false,
        type: 1,
        months: [
        { text: this.$t('january'), value: 1 },
        { text: this.$t('february'), value: 2 },
        { text: this.$t('march'), value: 3 },
        { text: this.$t('april'), value: 4 },
        { text: this.$t('may'), value: 5 },
        { text: this.$t('june'), value: 6 },
        { text: this.$t('july'), value: 7 },
        { text: this.$t('august'), value: 8 },
        { text: this.$t('september'), value: 9 },
        { text: this.$t('october'), value: 10 },
        { text: this.$t('november'), value: 11 },
        { text: this.$t('december'), value: 12 },
      ],
      currentMonth: null,
      currentYear:null,
      currentDay:null,
      maxYear: 0,
      };
    },
    created() {
      this.getAttendances();
    },
    methods: {
      handleRadioChange() {
        if(this.type == 1) {
          this.currentDay = this.currentMonth = this.currentYear = null;
        }
        this.getAttendances();
      },
      years() {
      var years = [];
      for(var i  = 0 ; i < 10 ; i++) {
        years.push({ text: ''+(this.maxYear - i), value: this.maxYear - i })
      }
      return years;
      },
      days() {
      var days = [];
      const lastDayOfMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();
      for(var i  = 1 ; i <= lastDayOfMonth ; i++) {
        days.push({ text: ''+i, value: i})
      }
      console.log('days : ',days);
      return days;
      },
      onEdit(item) {
        item.selected = !item.selected;
        console.log('changed');
      },
      addAttendances() {
        this.edit = true;
      },
      getTranslation(ar , en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function (e) {
        e.preventDefault();
        this.getAttendances();
      },
      getAttendances: function () {
        this.loading = true;
        this.$http
          .get("/backend/employees/attendances", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              year: this.currentYear,
              month: this.currentMonth,
              day: this.currentDay
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.attendances = data.body.employees;

                  this.date = data.body.date;
                  this.maxYear = data.body.current_year;
                  const searchDate = new Date(this.date);
                  this.currentYear = searchDate.getFullYear();
                  this.currentMonth = searchDate.getMonth() + 1;
                  this.currentDay = searchDate.getDate() + 1;

                  new Date(this.originalDate);
                  for (const employee of this.attendances) {
                    if(employee.attendances.length == 0) {
                      employee.selected = false;
                    }
                    else {
                      employee.selected = true;
                    }
                  }
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      editAttendances: function () {
        this.edit = false;
        this.saveLoading = true;
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };

        const selectedData = this.attendances.filter(function (item) {
          return item.selected;
        });
        
        const processedData = selectedData.map(function (item) {
          return {
            branch_id: item.branch_id,
            employee_id: item.id,
          };
        });

        console.log('attendance ',selectedData);
        this.$http
          .patch(
            "/backend/employees/editAttendance",
            {
              attendances : processedData,
              date: this.type == 1 ? '' : this.date
            },
            { headers }
          )
  
          .then(
            function (data) {
              this.saveLoading = false;
              switch (data.status) {
                case 200:
                  this.$emit("closePanel", {});
                  this.success(this.$t("success"));
                  this.getAttendances();
                  break;
              } 
            },
            (err) => {
              this.loading = false;
              this.saveLoading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },

      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>